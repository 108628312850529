export const VUEX_SESSION_USER = 'VUEX_SESSION_USER'
export const VUEX_USER_FETCH_AWS_CREDENTIALS = 'VUEX_USER_SET_AWS_CREDENTIALS'
export const VUEX_USER_HAS_ACCESS = 'VUEX_USER_HAS_ACCESS'
export const VUEX_USER_HAS_DEPARTMENT_ACCESS = 'VUEX_USER_HAS_DEPARTMENT_ACCESS'
export const VUEX_USER_HAS_CONFERENCE_ASIAN = 'VUEX_USER_HAS_CONFERENCE_ASIAN'
export const VUEX_USER_HAS_CONFERENCE_DOMESTIC = 'VUEX_USER_HAS_CONFERENCE_DOMESTIC'
export const VUEX_USER_HAS_CONFERENCE_INTERNATIONAL = 'VUEX_USER_HAS_CONFERENCE_INTERNATIONAL'
export const VUEX_USER_INIT = 'VUEX_USER_INIT'
export const VUEX_USER_LOGIN = 'VUEX_USER_LOGIN'
export const VUEX_USER_LOGOUT = 'VUEX_USER_LOGOUT'
export const VUEX_USER_SET = 'VUEX_USER_SET'
export const VUEX_USER_SET_AWS_CREDENTIALS = 'VUEX_USER_SET_AWS_CREDENTIALS'
export const VUEX_USER_SET_AWS_TOKEN = 'VUEX_USER_SET_AWS_TOKEN'
export const VUEX_USER_SET_REDIRECT_PATH = 'VUEX_USER_SET_REDIRECT_PATH'
